/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listMarketCarouselImage = params => axios({
    url: '/api/market_carousel_image/system/marketCarouselImage/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addMarketCarouselImage = params => axios({
    url: '/api/market_carousel_image/system/marketCarouselImage/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editMarketCarouselImage = params => axios({
    url: '/api/market_carousel_image/system/marketCarouselImage/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delMarketCarouselImage = params => axios({
    url:'/api/market_carousel_image/system/marketCarouselImage/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdMarketCarouselImage = params => axios({
    url: '/api/market_carousel_image/system/marketCarouselImage/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
