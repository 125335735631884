export const columns = [

  {
    title: '轮播名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '地址',
    dataIndex: 'address',
    key: 'address',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '位置',
    dataIndex: 'positionId',
    key: 'positionId',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },


  {
    title: '启用状态',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

  {
    title: '修改时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

]
